<template>
  <section class="main-body login">
    <v-card :class="'login__box'">
      <v-card-title :class="'login__box-gc'"
        ><img src="../..//assets/gamechanger-logo-purple.svg" alt="Gamechanger"
      /></v-card-title>
      You are not authorised to access this area of the system.
    </v-card>
  </section>
</template>

<script>
export default {
  name: "Unauthorised",

  mounted() {
    // console.log('Admin Users mounted');
  },
};
</script>
<style lang="scss" scoped>
.login {
  display: flex;
  justify-content: center;
  // height: calc(100vh - 60px);
  &__box {
    max-width: 400px;
    padding: 20px;
    width: 100%;
    &-gc {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 250px;
      }
    }
  }
}
</style>