<template>
  <section class="saved-by">
    <div class="saved-by__image">
      <v-img
        :src="saveData.authorImage"
        cover
        height="34"
        width="34"
      ></v-img>
    </div>

    <div class="saved-by__info">
      <p class="saved-by__author">Last saved on</p>
      <time class="saved-by__date" :datetime="saveData.lastSave">{{ saveData.lastSave | moment('Do MMMM YYYY @ kk:mm') }}</time>
    </div>
  </section>
</template>

<script>
export default {
  name: "SavedBy",

  props: {
    saveData: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .saved-by {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    &__image {
      display: none;
      border-radius: 50%;
      overflow: hidden;
    }

    &__info {
      margin: 0 0 0 10px;
    }

    &__author,
    &__date {
      padding: 0;
      margin: 0;
      color: #7B6B80;
      font-size: 14px;
      font-weight: 300;
    }
  }
</style>
