<template>
  <section class="tab-buffer">

    <label class="label" for="meta-title">Meta Title</label>
    <v-text-field
      id="meta-title"
      v-model="updatedData.metaTitle"
      solo
      flat
      required
      @change="updateData()"
    ></v-text-field>

    <label class="label" for="meta-keywords">Keywords</label>
    <v-text-field
      id="meta-keywords"
      v-model="updatedData.keywords"
      solo
      flat
      required
      @change="updateData()"
    ></v-text-field>

    <label class="label" for="meta-description">Description</label>
    <v-textarea
      id="meta-description"
      v-model="updatedData.description"
      solo
      flat
      required
      @change="updateData()"
    ></v-textarea>

    <template v-if="contentPage">
      <label class="label" for="custom-Schema">Schema</label>
      <p class="disclaimer">This area allows you to optionally add JSON-LD schema for pages. Please do not include the script tags and only embed the schema object. Urban Zoo does not take any responsibilty for the schema entered in this area.</p>
      <v-textarea
        id="meta-description"
        v-model="updatedData.customSchema"
        placeholder="{}"
        solo
        flat
        required
        @change="updateData(); validateSchema()"
      ></v-textarea>
      <p class="disclaimer">For more info - <a href="https://schema.org" target="_blank">schema.org</a>. For help validating JSON please visit <a href="https://codebeautify.org/jsonvalidator" target="_blank">codebeautify.org/jsonvalidator</a></p>
    </template>

    <SavedBy :saveData="saveData"/>

  </section>
</template>

<script>
import SavedBy from "@/components/Library/SavedBy"

export default {
  name: "EditSEO",

  components: {
    SavedBy
  },

  props: {
    data: {
      type: Object,
      required: true
    },
    saveData: {
      type: Object,
      required: true
    }
  },

  data: function () {
    return {
      updatedData: {},
      contentPage: false
    }
  },

  mounted() {
    console.log("Edit SEO mounted");
    this.updatedData = {
      metaTitle: this.data.metaTitle,
      keywords: this.data.keywords,
      description: this.data.description,
      customSchema: this.data.customSchema,
    }
    if (this.$route.name == "Page Content") {
      this.contentPage = true
    }
  },

  methods: {
    updateData() {
      this.$emit("update", this.updatedData)
    },

    validateSchema() {
      if (this.updatedData.customSchema) {
        if (this.isValidSchema(this.updatedData.customSchema)) {
          console.log("Valid schema")
        } else {
          console.log("Invalid schema")
          alert("Please check your schema, it may be invalid")
        }
      } else {
        console.log("No schema set")
      }
    },

    isValidSchema(schema) {
      try {
          JSON.parse(schema);
      } catch (e) {
          return false;
      }
      return true;
    },
  }
}
</script>

<style lang="scss" scoped>
  @import '../../styles/settings';

  .disclaimer {
    color: $grey;
  }

</style>>
