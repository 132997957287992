<template>
  <section class="global-settings">
    <div v-if="authorised === true">
      <v-tabs v-model="active">
        <v-tab :ripple="{ class: 'accent--text' }">Global SEO Values</v-tab>
        <v-tab-item px-0 class="main-body">
          <EditSEO
            v-if="dataLoaded"
            :data="seoData"
            :saveData="saveData"
            @update="updateSeoData($event)"
          />
        </v-tab-item>

        <!-- <v-tab :ripple="{ class: 'accent--text' }">Header Partner Logos</v-tab>
        <v-tab-item px-0 class="main-body">
          <PartnerLogos :isHeaderLogos="true" />
        </v-tab-item>

        <v-tab :ripple="{ class: 'accent--text' }">Footer Partner Logos</v-tab>
        <v-tab-item px-0 class="main-body">
          <PartnerLogos :isHeaderLogos="false" />
        </v-tab-item> -->

        <v-tab :ripple="{ class: 'accent--text' }">Club Contacts</v-tab>
        <v-tab-item px-0 class="main-body">
          <ClubContacts
            v-if="dataLoaded"
            :data="clubContactData"
            :saveData="saveData"
            @update="updateClubContactData($event)"
          />
        </v-tab-item>
      </v-tabs>

      <StickyFooter
        :errorMessage="errorMessage"
        :status="status"
        :buttonText="'Save'"
        @buttonClicked="save()"
      />
    </div>
    <div v-else>
      <Unauthorised></Unauthorised>
    </div>
  </section>
</template>

<script>
import axios from "axios";
// import PartnerLogos from '@/components/GlobalSettings/PartnerLogos'
import ClubContacts from "@/components/GlobalSettings/ClubContacts";
import EditSEO from "@/components/Library/EditSEO";
import StickyFooter from "@/components/Library/StickyFooter";
import Unauthorised from "@/components/Library/Unauthorised";

export default {
  name: "GlobalSettings",

  components: {
    // PartnerLogos,
    ClubContacts,
    EditSEO,
    StickyFooter,
    Unauthorised,
  },

  data: () => ({
    api: `${process.env.VUE_APP_BASEURL}/config`,
    active: null,
    authorised: false,
    dataLoaded: false,
    status: "saved",
    errorMessage: "",
    configID: "",
    saveData: {
      author: "AUTHOR NOT SET",
      authorImage: "/favicon-32x32.png",
      lastSave: null,
    },
    seoData: {
      metaTitle: "",
      keywords: "",
      description: "",
    },
    clubContactData: {
      emailAddress: "",
      telephone: "",
      address: "",
    },
  }),
  mounted() {
    console.log("Global settings mounted");
    this.$getCurrentUserGroups().subscribe(
      (userGroups) => {
        if (
          userGroups.includes("Administrator") ||
          userGroups.includes("Content")
        ) {
          this.authorised = true;
          this.fetchData();
        } else {
          this.authorised = false;
        }
      },
      (err) => {
        this.authorised = false;
        console.log(err);
      }
    );
  },

  methods: {
    fetchData() {
      this.errorMessage = "";
      this.$store.commit("startLoading");
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        // console.log('User Details', jwt )
        axios
          .get(`${this.api}`, { headers: { Authorization: jwt } })
          .then((response) => {
            // console.log('fetchData response: ', response);

            this.configID = response.data.body.configID;

            this.seoData = response.data.body.seo;
            if (this.seoData === undefined) {
              this.seoData = {
                metaTitle: response.data.body.seo.metaTitle,
                keywords: response.data.body.seo.keywords,
                description: response.data.body.seo.description,
              };
            }

            this.clubContactData = response.data.body.clubContact;
            if (this.clubContactData === undefined) {
              this.clubContactData = {
                emailAddress: response.data.body.clubContact.emailAddress,
                telephone: response.data.body.clubContact.telephone,
                address: response.data.body.clubContact.address,
              };
            }

            this.saveData = {
              lastSave: response.data.body.savedTimestamp,
            };

            this.dataLoaded = true;
            this.$store.commit("completeLoading");
          })
          .catch((e) => {
            console.error("Problem retrieving page: ", e);
            this.$store.commit("completeLoading");
            this.errorMessage = "Problem retrieving page";
          });
      });
    },

    save() {
      console.log("Going to save");
      this.status = "saving";
      this.errorMessage = "";

      this.$getCurrentUserJwToken().subscribe((jwt) => {
        // console.log('User Details', jwt )
        axios({
          method: "POST",
          url: `${this.api}`,
          headers: { Authorization: jwt },
          data: {
            configID: this.configID,
            seo: {
              metaTitle: this.seoData.metaTitle,
              keywords: this.seoData.keywords,
              description: this.seoData.description,
            },
            headerLogos: [],
            footerLogos: [],
            clubContact: {
              emailAddress: this.clubContact.emailAddress,
              telephone: this.clubContact.telephone,
              address: this.clubContact.address,
            },
          },
        })
          .then((response) => {
            // console.log("Saved Page:", response);
            this.status = "saved";
          })
          .catch((e) => {
            console.error("Problem saving page: ", e);
            this.status = "needsSaving";
            this.errorMessage = "Problem saving page";
          });
      });
    },

    updateConfigData(payload) {
      // console.log("updateConfigData: ", payload);
      this.status = "needsSaving";
      this.configData = payload;
    },

    updateSeoData(payload) {
      // console.log("updateSeoData: ", payload);
      this.status = "needsSaving";
      this.seoData = payload;

      // console.log("updateSeoData data: ", this.seoData);
    },

    updateClubContactData(payload) {
      // console.log("updateClubContactData: ", payload);
      this.status = "needsSaving";
      this.clubContact = payload;
    },
  },
};
</script>
