<template>
  <section class="tab-buffer">
    <label class="label" for="email-address">Email Address</label>
    <v-text-field
      id="email-address"
      v-model="updatedData.emailAddress"
      solo
      flat
      required
      @click="updateData"
    ></v-text-field>

    <label class="label" for="telephone">Telephone</label>
    <v-text-field
      id="telephone"
      v-model="updatedData.telephone"
      solo
      flat
      required
      @click="updateData"
    ></v-text-field>

    <label class="label" for="address">Address</label>
    <v-textarea
      id="address"
      v-model="updatedData.address"
      solo
      flat
      required
      @click="updateData"
    ></v-textarea>

    <SavedBy :saveData="saveData"/>

  </section>
</template>

<script>
import SavedBy from "@/components/Library/SavedBy"

export default {
  name: "ClubContacts",

  components: {
    SavedBy
  },

  props: {
    data: {
      type: Object,
      required: true
    },
    saveData: {
      type: Object,
      required: true
    }
  },

  data: function () {
    return {
      updatedData: {}
    }
  },

  mounted() {
    console.log("Club Contact mounted");
    this.updatedData = {
      emailAddress: this.data.emailAddress,
      telephone: this.data.telephone,
      address: this.data.address
    }
  },

  methods: {
    updateData() {
      this.$emit("update", this.updatedData)
    }
  }
}
</script>

